<template>
    <div class="search-box">
      <form action="/" class="fix-top">
        <van-search
            v-model="hosName"
            show-action
            placeholder="请输入医院关键词"
            @search="getHosList"
            @cancel="onCancel"
            @update:model-value="debounce"

        />
      </form>
      <div style="height:62px;"></div>
      <div class="content">
        <div class="list_box">
          <div class="list"
               v-for="(item,index) in hosList"
               @click="getHospitalName(item)"
          >
            <div>
              <van-image
                  round
                  width="60px"
                  height="60px"
                  fit="cover"
                  :src="item.hospitalPic"
              >
              </van-image>
            </div>

            <!-- <img class="icon" :src="item.pic" alt=""> -->
            <div class="l_box">
              <p class="tit">{{item.name}}</p>
              <p class="sub">{{item.city}} | {{item.grade}}<van-tag color="#00C291">{{item.hospitalNum}}位医生</van-tag></p>
              <p class="txt">简介：{{item.introduction}}</p>
            </div>
            <van-icon name="arrow" class="right" color="#999999" size="18"/>
          </div>
          <van-empty v-if="hosList.length == 0" description="暂无更多内容" />
        </div>
      </div>
    </div>
</template>

<script>
import { useRouter,useRoute } from 'vue-router'
import { ref} from 'vue';
import { Toast } from 'vant';
import {useStore,} from 'vuex'
import homeApi from '@axios/home'
export default {
  name: "searchPage",
  setup(props, {emit}) {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const hosName = ref('');
    const onSearch = (val) => Toast(val);
    const onCancel = () => {
      router.back()
    };

    const hosList = ref([])
    const getHosList = () => {
      console.log(112)
      let formData = new FormData();
      formData.append('data', JSON.stringify({
        userId: store.state.uid + '',
        source:"ios_users_nurseHome_014",
        timeStamp:"2731717",
        _cityCode:"131",
        keyWord:hosName.value.trim(),
        limit:"20",
        login_userId_base:store.state.uid + '',
        version:"2.103",
        sid:"80009000075",
        function:"searchGhHospitaByKeyWord",
        token: store.state.token,
        newversion:store.state.newversion,
      }))
      formData.append('encryption', false)
      homeApi.getHosSearch(formData).then((res)=> {
        hosList.value = res.data;
      })
    }
    getHosList();
    const getHospitalName = (item) => {
       const queryObj = parseQuery(router.options.history.state.back);
       console.log(queryObj)
       Object.keys(queryObj).forEach( key => {
         console.log(key)
         if(key == 'hosName'){
           queryObj[key] = item.name;
         }else{
           queryObj['hosName'] = item.name
           queryObj['hospitalId'] = item.hospitalId
           queryObj['cityCode'] = item.cityCode
           queryObj['latitude'] = item.latitude
           queryObj['longitude'] = item.longitude
           queryObj['targetCityCode'] = item.cityCode
         }
      })
      console.log(queryObj)
      router.push({
          path:'/pzhenSorder',
          query:queryObj
      })
    }


    let timeout = null;
    const debounce = () => {
        if(timeout){
          clearTimeout(timeout)
        }
        timeout = setTimeout(() => {
          getHosList()
        }, 800)
    }
    const parseQuery = (url) =>{
      let o = {};
      let queryString = url.split("?")[1];
      if (queryString) {
        queryString.split("&").forEach(item => {
          let [key, val] = item.split("=");
          val = val ? decodeURI(val) : "";
          //          转码         无值赋值true
          if (o.hasOwnProperty(key)) {
            //   已有属性转为数组
            o[key] = [].concat(o[key], val);
          } else {
            o[key] = val;
          }
        });
      }
      return o;
    }

    return {
      getHospitalName,
      debounce,
      hosName,
      getHosList,
      hosList,
      onSearch,
      onCancel,
      router,
      route
    };
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.fix-top{
   width: 100%;
   height: 124px;
   position: fixed;
   left:0;top:0;
   z-index: 1000;
}
.van-search--show-action{
  height:124px;
}
::v-deep(.van-search__action){
  color:#999999;
}
::v-deep(.van-cell){
  color:#999999;
  height:72px;
}

::v-deep(.van-field__left-icon .van-icon){
  font-size: 38px;
}

p{
  margin:0;padding:0;
}
   .search-box{
     font-size: 28px;
     width: 100%;
     height: 100vh;
     background: #f7f8fa;
   }
   .list_box{
     font-family: PingFangSC-Medium, PingFang SC;
     .list{
       border-bottom: 1px solid #E0E0E0; ;
       padding:30px;
       display: flex;
       justify-content: space-between;
       align-items: center;
       font-size: 24px;
       .l_box{
         margin-left: 20px;
       }
       .icon{
         width: 120px;
         height: 120px;
         margin-right: 20px;
         border-radius: 50%;
       }
       .tit{
         font-size: 30px;
         font-weight: 500;
         color: #333333;
       }
       .sub{
         margin:10px 0;
         color:#999999;
         font-size: 24px;
       }
       .txt{
         color:#999999;
         font-size: 24px;
         padding-right:10px;
         width: 520px;

         white-space:nowrap;/* 规定文本是否折行 */
         overflow: hidden;/* 规定超出内容宽度的元素隐藏 */
         text-overflow: ellipsis;
       }
       .right{

       }
     }
   }
</style>
